import { remove } from "lodash";
import consumer from "./consumer"

let lastMileManifestChannel = null;

$(document).on("turbolinks:load", function(e) {
  const last_mile_manifest_id = $("#last-mile-manifest-channel-provider").data("id");

  if (lastMileManifestChannel) {
    lastMileManifestChannel.unsubscribe();
    lastMileManifestChannel = null;
    console.log("Disconnected from last_mile_manifest channel");
  }
  
  if (last_mile_manifest_id) {
    lastMileManifestChannel = consumer.subscriptions.create({ channel: "LastMileManifestChannel", id: last_mile_manifest_id }, {

      connected() {
        // Called when the subscription is ready for use on the server
        console.log(`Connected to channel last_mile_manifest:${last_mile_manifest_id}`)
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        console.log(`Disconnected from channel last_mile_manifest:${last_mile_manifest_id}`)
      },

      received(data) {
        if (data.action == "destroy") {
          var element = document.getElementById(data.resource_kind + "-" + data.resource_id)
          element.classList.add("animate__animated");
          element.classList.add("animate__bounceOutLeft");
          setTimeout(function() {
            element.remove();
          }, 750);
          
        } else if (data.action == "create") {
          var container = document.querySelector(".parcels_destinations");
          var element = document.createElement('div');
          element.classList.add("destination-tag-margin")
          element.classList.add("animate__animated")
          element.classList.add("animate__bounceInLeft")
          element.innerHTML = data.body;
          document.getElementById("resource_code").value = ""
          container.prepend(element);
        }

        document.getElementById("objects_counter").textContent = data.quantity
        document.getElementById("objects_total_big_counter").textContent = data.quantity
      }
    });
  }
})
