import consumer from "./consumer"

let waybillChannel = null;

$(document).on("turbolinks:load", function(e) {
  const waybill_id = $("#waybill-channel-provider").data("id");

  if (waybillChannel) {
    waybillChannel.unsubscribe();
    waybillChannel = null;
    console.log("Disconnected from waybill channel");
  }
  
  if (waybill_id) {
    waybillChannel = consumer.subscriptions.create({ channel: "WaybillChannel", id: waybill_id }, {

      connected() {
        // Called when the subscription is ready for use on the server
        console.log(`Connected to channel waybill:${waybill_id}`)
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
        console.log(`Disconnected from channel waybill:${waybill_id}`)
      },

      received(data) {
        var container = document.querySelector(".destinations");
        var element = document.createElement('div');
        element.classList.add("destination-tag-margin")
        element.classList.add("animate__animated")
        element.classList.add("animate__bounceInLeft")
        element.innerHTML = data.body;
        if (gon.user_id == data.author_id) {
          container.prepend(element);
        } else {
          container.appendChild(element);
        }
        document.getElementById("destinations_counter").textContent = data.quantity
        document.getElementById("destinations_big_counter").textContent = data.quantity
        document.getElementById("parcel_code").value = ""
      }
    });
  }
})
