import "core-js/stable";
import "regenerator-runtime/runtime";
import Turbolinks from "turbolinks";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import Rails from '@rails/ujs';
import "jquery";
import '../src/office/scss/office.scss';
import "../src/office/images";

// Utilities
import "../src/utilities/js/bulma_dropdown";
import "../src/utilities/js/bulma_pageloader";
import "../src/utilities/js/returning_parcels_block_toggler";
import "../src/utilities/js/bulma_divider";
import "../src/utilities/js/imask";
import "../src/utilities/js/file_input";
import "../src/utilities/js/timezone_field";
import "../src/utilities/js/select2";
import "../src/utilities/js/auto_focus";
import "../src/utilities/js/time_helper";
import "../src/utilities/js/flatpickr_date";
import "../src/utilities/js/flatpickr_date_unlim";
import "../src/utilities/js/flatpickr_time";
import "../src/utilities/js/flatpickr_reports_date";
import "../src/utilities/js/flatpickr_date_and_time";
import "../src/utilities/js/flatpickr_date_maxed";
import "../src/utilities/js/burger_dropdown";
import "../src/utilities/js/modal_trigger";
import "../src/utilities/js/tag_input";
import '../src/utilities/js/bulma_badge';
import "../src/utilities/js/quick_view";
import "../src/utilities/js/expandable_content";
import "../src/office/js/ui/comment_edit";
import "../src/office/js/ui/filter_form";
import "@fortawesome/fontawesome-free/js/all.js";
import "@fortawesome/fontawesome-free/css/all.css";
import '@cityssm/bulma-sticky-table/bulma-with-sticky-table.scss';
import "../src/office/js/ui/receiver_address_autocomplete";
import "../src/office/js/ui/sender_address_autocomplete";
import "../src/office/js/ui/additional_services_autocomplete";
require("channels")

const application = Application.start();
const context = require.context("src/office/controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

Turbolinks.start();
Rails.start();